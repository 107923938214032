.verify-dark-bg {
    background-color: #090023;
    color: white;
}

.center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.container {
    display: flex;
    justify-content: space-between;
}

.character {
    width: 32px;
    height: 32px;
    border: none;
    font-size: 20px;
    text-align: center;

    /* light theme */
    color: #272729;
    background-color: #090023;

    /* dark theme */
    color: #fff;
    background-color: #090023;
}

/* Style the currently typing number (white) */
.character--selected {
    border-bottom: 2px solid white;
}

/* Style the future numbers (gray) */
.character--inactive {
    border-bottom: 2px solid #666;
}

/* Hide the actual input element */
.hidden-input {
    position: absolute;
    left: -9999px;
}

/* HesMine.tsx */
.hes-mine-bg {
    background: #646AFF;
}

/* FindYourMan.tsx */
.find-your-man-bg {
    background: #090023;
}

/* Reveal.tsx */
@keyframes blink {
    0% {opacity: 1;}
    50% {opacity: 0.5;}
    100% {opacity: 1;}
}

.animate-blink {
    animation: blink 1s infinite;
}

/* Landing.tsx */

/* Loading.tsx */
@keyframes dotBlink1 {
    0% { opacity: 0; }
    10% { opacity: 1; }
}

@keyframes dotBlink2 {
    0%, 100% { opacity: 0; }
    20% { opacity: 1; }
}

@keyframes dotBlink3 {
    0%, 100% { opacity: 0; }
    30% { opacity: 1; }
}

.animate-dot1 {
    animation: dotBlink1 1.5s infinite;
}

.animate-dot2 {
    animation: dotBlink2 1.5s infinite;
}

.animate-dot3 {
    animation: dotBlink3 1.5s infinite;
}

.lock-border {
    display: inline-block;
    padding: 15px;
    border: 33px solid transparent;
    -moz-border-image: url("../Assets/lock-border.png") 33 repeat;
    -webkit-border-image: url("../Assets/lock-border.png") 33 repeat;
    -o-border-image: url("../Assets/lock-border.png") 33 repeat;
    border-image: url("../Assets/lock-border.png") 33 repeat;
}

.amplify-button {
    margin-bottom: 4rem;
}