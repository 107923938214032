.dialog-panel-dark-bg {
    background-color: #090023;
    color: white;
}

.photo-upload-bg {
    background-color: #FF8D8D;
    transition: background-color 0.2s ease-in-out;
}

.photo-upload-container:hover .photo-upload-bg {
    background-color: #ffcece;
}

.photo-upload-plus-bg {
    background-color: #000425;
    transition: background-color 0.2s ease-in-out;
}

.photo-upload-container:hover .photo-upload-plus-bg {
    background-color: #4649a2;
}

.deck {
    position: absolute;
    will-change: transform;
    display: flex;
    align-items: center;
    justify-content: center;
    touch-action: none;
}
  
.deck > div {
    touch-action: none;
    background-color: white;
    background-size: auto 85%;
    background-repeat: no-repeat;
    background-position: center center;
    width: 40vh;
    max-width: 300px;
    height: 60vh;
    max-height: 570px;
    will-change: transform;
    border-radius: 10px;
    box-shadow: 0 12.5px 50px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
}

@keyframes flip {
    0% {
        transform: scaleY(1);
    }
    50% {
        transform: scaleY(0);
    }
    100% {
        transform: scaleY(1);
    }
}

.animate-flip {
    animation: flip 1s ease-in-out forwards;
}


/* EmojiConveyerBelt.tsx */
@keyframes swirl {
  0%, 100% {
      transform: rotate(0);
  }
  25% {
      transform: rotate(90deg);
  }
  50% {
      transform: rotate(180deg);
  }
  75% {
      transform: rotate(270deg);
  }
}

.animation-swirl {
  animation-name: swirl;
}


/* IncomingChatBubble.tsx */
@keyframes blinkFast {
  0%, 20%, 50%, 80%, 100% {
    opacity: 0.5;
  }
  40% {
    opacity: 1;
  }
}

@keyframes blinkSlow {
  0%, 20%, 80%, 100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

.animate-blink-fast {
  animation: blinkFast 1.5s infinite;
}

.animate-blink-slow {
  animation: blinkSlow 1.5s infinite;
}

/* TestimonialCarousel.tsx */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* HorizontalCarousel.tsx */
.horizontal-carousel::-webkit-scrollbar {
  display: none;
}

.horizontal-carousel {
  scrollbar-width: none;  /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

.horizontal-carousel {
  cursor: grab;
  position: relative;
  z-index: 1;
}

.horizontal-carousel:active {
  cursor: grabbing;
}
